import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询检查配置列表
export function listCheckTable(query: any) {
  return request({
    url: "/factory/checkTable/list",
    method: "get",
    params: query,
  });
}

// 查询检查配置详细
export function getCheckTable(id: any) {
  return request({
    url: `/factory/checkTable/detail/${id}`,
    method: "get",
  });
}

// 新增检查配置
export function addCheckTable(data: any) {
  return request({
    url: "/factory/checkTable",
    method: "post",
    data: data,
  });
}

// 修改检查配置
export function updateCheckTable(data: any) {
  return request({
    url: "/factory/checkTable",
    method: "put",
    data: data,
  });
}

// 删除检查配置
// export function delCheckTable(data: any) {
//   return request({
//     url: "/factory/checkTable/removes",
//     method: "delete",
//     data: data,
//   });
// }
// 操作检查配置
export function delCheckTable(data: any) {
  return request({
    url: "/factory/checkTable/operate",
    method: "post",
    data: data,
  });
}
